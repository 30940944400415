import React, { FC } from 'react'
import { PageTemplate } from '../components/pageTemplate'
import { PostHeader } from '../components/postHeader'

import cat1 from '../images/cat_1.png'
import cat1_small from '../images/cat_1-300x193.png'
import event_medal from '../images/etc_event_medal_i00.png'
import event_glitter_medal from '../images/etc_event_glitter_medal_i00.png'
import cat3_small from '../images/cat_3-300x211.jpg'


// http://www.dktech.cz/?paged=2
// http://www.dktech.cz/?p=247

const PostVelikonocniEventAmuletyMoci: FC = () => {
  return (
    <PageTemplate
      prevLink="/post_ukonceni_zkusebni_doby"
      nextLink="/post_novi_gamemasters"
    >
      <PostHeader
        title="Velikonoční event – Amulety moci"
        link="/post_velikonocni_event_amulety_moci"
        author="Algy"
        date="17.04.2014"
        time="10:19"
        category="Eventy"
      />
      <h6 style={{ textAlign: 'justify' }}>Konání: čtvrtek 17. dubna 2014 12:00 – neděle 27. dubna 2014 23:59 NPC pro výměnu itemů budou spawnuty do středy 30. dubna 2014 23:59</h6>
      <p style={{ textAlign: 'justify' }}>
      </p><p style={{ textAlign: 'justify' }}><strong>Na vývěsních deskách, na sloupech, ale i na stromech, kostelech a budovách vidíte důležitou výzvu:</strong></p>
      <p style={{ textAlign: 'justify' }}>“Vážení dobrodruhové Adenského a Elmorského panství,</p>
      <p style={{ textAlign: 'justify' }}>jsou tomu již řady let, co Slonovinové věži vládl zřejmě nejsilnější a nejmocnější mág všech dob – Beleth. Beleth byl posedlý magií Obrů, neboť toužil moc pradávné magie&nbsp;využít pro své plány k ovládnutí světa. Svoji přebytečnou temnou magickou moc ukrýval Beleth “na horší časy” do tísiců silných amuletů – Amultů moci.</p>
      <p><a style={{ fontSize: '10px', fontWeight: 'bold', textAlign: 'justify' }} href={cat1}><img className="alignright size-medium wp-image-231" alt="cat_1" src={cat1_small} width="300" height="193" /></a></p>
      <p style={{ textAlign: 'justify' }}>Kromě náročného studia se&nbsp;věnoval i shánění a schraňování magických předmětů,&nbsp;kouzelných svitků a léčivých nápojů. A věřte, že jich za ta léta nahromadil víc, než leckterý z mocných draků za celý&nbsp;život! Jak jistě víte, Belethovi plány začaly postupem času ohrožovat samotnou podstatu světa, a proto proti němu ostatní mágové Slonovinové věže sdružení v Alianci povstali.&nbsp;Následovala léta krutých magických válek, která skončila úplným vítězstvím Aliance a vyhnáním Beletha na vzdálený ostrov Hellbound.</p>
      <p style={{ textAlign: 'justify' }}>Zatímco se mocní bili o vládu, statisíce&nbsp;Belethových silných amuletů se ztratilo. Aliance mágů se domnívá, že Amulety moci obsahují dohromady tolik zla, že by mohly být rozhodující pro Belethův návrat z vyhnanství.&nbsp;Dlouho proto pátrali po tom, kam se amulety poděly. Ovšem bez úspěchu. Až poslední dobou, zdá se, se začaly amulety objevovat u různých monster, která ve světě po magických&nbsp;válkách zůstala.</p>
      <p style={{ textAlign: 'justify' }}><strong><em>“Domníváme se, že Belethovi věrní rozdali všem stvůrám tohoto světa Amulety moci a plánují návrat svého pána,”</em></strong> stojí ve včerejší žádosti mágů Slonovinové věže adresované všem&nbsp;vládcům. <strong><em>“Pro dobro všeho živého pomozte Amulety vrátit pod kontrolu. Do všech velkých měst i do všech vesnic posíláme své služebníky. Abyste je poznali, vzali na sebe podobu&nbsp;koček a kocourů. I nadále však mluví lidskou řečí. Dáváme jim s sebou množství věcí z Belethova pokladu. Získání Amuletů moci má pro nás prioritu,”</em></strong> pokračuje dopis.</p>
      <p style={{ textAlign: 'justify' }}>Vyzýváme proto dobrodruhy všech ras, povolání a přesvědčení – připojte se k lovu monster a hledání odcizených Amuletů moci. Nevíme, které odměny si pro vás služebníci mágů&nbsp;připravili, však pamatujte, že vše děláte hlavně pro záchranu našeho světa!</p>
      <p style={{ textAlign: 'justify' }}>Vyzýváme vás, abyste své hrdinství v příštích dnech dokázali!</p>
      <p style={{ textAlign: 'right' }}>Vládci Adenu a Elmoru”</p>
      <p style={{ textAlign: 'justify' }}>——-</p>
      <p style={{ textAlign: 'center' }}>Po celém světě začnou v pravé poledne 17. dubna 2014 padat z monster medaile. Některé si zachovaly původní lesk <a href={event_glitter_medal}><img className="size-full wp-image-228 alignleft" alt="etc_event_glitter_medal_i00" src={event_glitter_medal} width="32" height="32" /></a>&nbsp;jiné už jsou značně ošuntělé.<a href={event_medal}><img className="size-full wp-image-229 alignright" alt="etc_event_medal_i00" src={event_medal} width="32" height="32" /></a></p>
      <p style={{ textAlign: 'justify' }}>
      </p><p style={{ textAlign: 'justify' }}><span style={{ fontSize: '13px' }}>Za zachovalé Amulety moci můžete při troše štěstí (minihra panna nebo orel) u kočky “Winnie the Cat” zvyšovat úroveň lovce monster z neškodného králíčka až po mocného vlka,&nbsp;</span><span style={{ fontSize: '13px' }}>kterého se bojí všechna mostra v okolí.</span></p>
      <p><img className="size-medium wp-image-232 alignright" alt="cat_3" src={cat3_small} width="300" height="211" /> Rabbit – 5 glittering medal Hyena – 10 glittering medal Fox – 20 glittering medal Wolf – 40 glittering medal</p>
      <p style={{ textAlign: 'justify' }}>Výměnou za ošuntělé (obyčejné) Amulety moci vám kočička “Roy the Cat” v závislosti na vaší úrovni lovce umožní získat různé odměny.</p>
      <p style={{ textAlign: 'justify' }}>Mágové také vyslali do světa proslulého vandráka Luie (Lui the Cat), který vám nabídne všechny věci, které si nese v batůžku a to bez ohledu na vaši úroveň lovce. Pozor ale,&nbsp;Lui je vydřiduch a hamtal v jedné osobě!!!</p>


      <div className="cleared">&nbsp;</div>
    </PageTemplate>
  )
}

export default PostVelikonocniEventAmuletyMoci

export { Head } from '../components/defaultHtmlHead'